<template>
  <div class="page" id="corrupted-rows">
    <div class="mt-4 head-actions">
      <button class="px-3 btn btn-danger btn-sm" @click="stopProcessTheFile">
        <i class="fa fa-times"></i>
        {{ $t('call-survey.corrupted-rows.actions.stop') }}
      </button>
      <!-- ignore all -->
      <button
        class="px-3 btn btn-sm btn-warning"
        @click="ignoreAllCorruptedRows"
      >
        <i class="fa fa-trash"></i>
        {{ $t('call-survey.corrupted-rows.actions.ignore') }}
      </button>
    </div>

    <data-table
      ref="corruptedCustomersTable"
      :data="corruptedRows"
      :fields="translatedTableFields"
      :pagination="pagination"
      @changePage="onPageChange($event)"
      :loading="corruptedRowLoading"
    >
      <template v-slot:reasons="{ row }">
        <div class="reasons">
          <b-badge
            v-for="reason in row.reasons"
            :key="reason"
            variant="warning"
            class="m-2 white-space-pre-line"
          >
            {{ reason }}
          </b-badge>
        </div>
      </template>
      <template v-slot:actions="{ row }">
        <button class="px-3 btn btn-primary btn-sm" @click="toggleDetails(row)">
          <i
            :class="
              $refs.corruptedCustomersTable.activeRowDetailsId === row.id
                ? 'fa fa-eye-slash'
                : 'fa fa-eye'
            "
          ></i>
        </button>
      </template>
      <template v-slot:details="{ row }">
        <div class="details-form">
          <div class="row">
            <Field
              class="col-12 col-lg-6"
              :label="$t('call-survey.corrupted-rows.form.name.label')"
              :placeholder="
                $t('call-survey.corrupted-rows.form.name.placeholder')
              "
              v-model="corruptedRowModel.name"
              :error="corruptedRowErrors[`name`]"
            />

            <Field
              class="col-12 col-lg-6"
              :label="$t('call-survey.corrupted-rows.form.phone.label')"
              :error="corruptedRowErrors[`phone`]"
            >
              <b-form-input
                class="border"
                :placeholder="
                  $t('call-survey.corrupted-rows.form.phone.placeholder')
                "
                v-model="corruptedRowModel.phone"
                type="number"
                min="1"
                @keydown="onlyAcceptNumbers"
              />
            </Field>
          </div>

          <div class="">
            <button
              class="btn btn-primary btn-sm"
              @click="fixCorruptedRow(row)"
            >
              {{ $t('call-survey.corrupted-rows.form.fix-and-save') }}
            </button>
          </div>
        </div>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from '../../../elements/Table.vue'
import Field from '../../../elements/Field.vue'
import { onlyAcceptNumbers } from '../../../utils/utils'
import { isValidPhoneNumber } from '../../../utils/phone'
import { yupToKV } from '../../../utils/yup'
import { object, string } from 'yup'
import CallSurveyService from '../../../services/call-survey.service'
import { mapGetters } from 'vuex'

export default {
  name: 'CorruptedRows',
  components: {
    DataTable,
    Field
  },
  data() {
    return {
      corruptedRowLoading: false,
      fields: [
        {
          accessor: 'status',
          header: 'call-survey.corrupted-rows.table.status'
        },
        {
          accessor: 'reasons',
          header: 'call-survey.corrupted-rows.table.reasons'
        },
        {
          accessor: 'actions',
          header: 'call-survey.corrupted-rows.table.actions'
        }
      ],
      corruptedRows: [],
      pagination: {
        totalPages: 0,
        page: 1
      },
      corruptedRowModel: {
        name: '',
        phone: ''
      },
      corruptedRowErrors: {}
    }
  },
  methods: {
    onlyAcceptNumbers(event) {
      onlyAcceptNumbers(event)
    },
    onPageChange(page) {
      this.pagination.page = page
      this.fetchCorruptedRows(page)
    },
    async fetchCorruptedRows(page) {
      try {
        this.corruptedRowLoading = true
        const {
          corruptedRows,
          ...pagination
        } = await CallSurveyService.getCorruptedRows(
          this.$route.params.id,
          page
        )

        this.corruptedRows = corruptedRows
        this.pagination.totalPages = pagination.totalPages
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message

        console.log(errorMessage)
        this.toast(errorMessage, {
          type: 'error'
        })
      } finally {
        this.corruptedRowLoading = false
      }
    },
    toggleDetails(row) {
      if (!row.row) return
      this.corruptedRowErrors = {}
      this.corruptedRowModel = row.row

      this.$refs.corruptedCustomersTable.toggleDetails(row.id)
    },
    async fixCorruptedRow(row) {
      try {
        await this.schema
          .validate(this.corruptedRowModel, { abortEarly: false })
          .catch(err => {
            this.corruptedRowErrors = yupToKV(err)
          })

        if (!this.schema.isValidSync(this.corruptedRowModel)) {
          return
        }

        await CallSurveyService.fixCorruptedRow(
          this.$route.params.id,
          row.id,
          this.corruptedRowModel
        )
        this.toast(
          this.$t('call-survey.corrupted-rows.toasts.corrupted-row-fixed'),
          { type: 'success' }
        )

        if (this.corruptedRows.length === 1) {
          this.$router.push({
            name: 'call-survey'
          })
        }
        this.fetchCorruptedRows()
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, { type: 'error' })
      } finally {
      }
    },
    async ignoreAllCorruptedRows() {
      try {
        await CallSurveyService.ignoreAllCorrupted(this.$route.params.id)
        this.toast(
          this.$t('call-survey.corrupted-rows.toasts.corrupted-rows-ignored')
        )
        this.$router.push({
          name: 'call-survey'
        })
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, { type: 'error' })
      }
    },
    async stopProcessTheFile() {
      try {
        await CallSurveyService.cancelSurvey(this.$route.params.id)
        this.toast(
          this.$t('call-survey.corrupted-rows.toasts.file-processing-stopped')
        )
        this.$router.push({
          name: 'call-survey'
        })
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, { type: 'error' })
      }
    }
  },
  computed: {
    ...mapGetters(['currentTenant']),
    translatedTableFields() {
      return this.fields.map(field => {
        return {
          ...field,
          header: this.$t(field.header)
        }
      })
    },
    schema() {
      return object().shape({
        name: string()
          .trim()
          .required(this.$t('call-survey.corrupted-rows.form.name.required')),
        phone: string()
          .trim()
          .test(
            'isValidPhone',
            this.$t('call-survey.corrupted-rows.form.phone.invalid'),
            isValidPhoneNumber
          )
          .required(this.$t('call-survey.corrupted-rows.form.phone.required'))
      })
    }
  },
  mounted() {
    this.fetchCorruptedRows()
  },
  watch: {
    async currentTenant() {
      this.fetchCorruptedRows()
    }
  }
}
</script>

<style scoped>
.details-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.head-actions {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.white-space-pre-line {
  white-space: pre-line;
}
</style>
